import request from './request'
import { QueryPageDto } from './dto'

export interface PayrollDto {
    id: number
    userName: string
    projectName: string
    paymentAmount: number
    payTime: string
    getSalaryTime: string
    billStatusMsg: string
    statusMsg: string
    bodyInfo: string[]
}
interface PayrollResultDto {
    list: PayrollDto[]
    total: number
}
interface GetPayrollDetailListDto extends QueryPageDto {
    stubId: string | string[]
}
interface PostPayrollDto {
    name: string
    totalMoney: string | number
    peopleNum: string | number
    serveCompanyId: number | string
    serveCompanyName: string | number
    month: string | number
    bodyInfo: {[x: string]: string | number }[]
    headerList: { index: number, key: string, value: string }[]
}

// 获取工资单列表
export function getPayrollList (data: {
    searchText: string
    pageSize: number
    pageNum: number
}): Promise<PayrollResultDto> {
    return request.get('/rest/pay-stub/list', {
        params: data
    })
}
// 获取工资单详情抬头
export function getPayrollDetailHeader (
    data: string | string[]
): Promise<{ value: string }[]> {
    return request.get('/rest/pay-stub/getStubHeader' + '/' + data)
}
// 获取工资单详情列表
export function getPayrollDetailList (
    data: GetPayrollDetailListDto
): Promise<PayrollResultDto> {
    return request.get('/rest/pay-stub-detail/list', {
        params: data
    })
}
// 删除勾选工资单 rest/pay-stub/
export function payrollDelete (data: string): Promise<void> {
    return request.delete('/rest/pay-stub/' + data)
}
// 删除勾选工资单详情 rest/pay-stub-detail/{ids}
export function payrollDetailDelete (data: string): Promise<void> {
    return request.delete('/rest/pay-stub-detail/' + data)
}
// 发送工作单
export function postPayroll (data: PostPayrollDto): Promise<void> {
    return request.post('/rest/pay-stub', data)
}
